<template>
    <div>
        <v-card>
            <h2 class="pl-2">
                {{ $t('users.administrators' )}}
            </h2>
            <v-btn
              color="blue"
              dark
              class="float-right mr-10"
              @click.stop="addOrEditUser()"
            >
            {{ $t('common.add' )}}
            <v-icon class="ml-2">mdi-account-plus</v-icon>
          </v-btn>
          <div style="clear:both"></div>
        <v-data-table
        :headers=headers
        :items=users
        >
        <template slot="item.actions" slot-scope="props" class="justify-center">
            <v-btn
              class="mx-2"
              icon
              @click="addOrEditUser(props.item)"
            ><v-icon dark>mdi-pencil</v-icon>
            </v-btn>
            <v-btn v-if="props.item.id != $store.getters.currentUserId"
              class="mx-2"
              icon
              @click="deleteUser(props.item)"
            ><v-icon dark>mdi-delete</v-icon>
            </v-btn>
        </template>
        </v-data-table>
        </v-card>
        <add-or-edit-user-dialog
          ref="addOrEditDialog"
          @submit="getDataAsync"
        ></add-or-edit-user-dialog>
        <confirm-delete-dialog
          endPoint="/User/DeleteUser"
          ref="confirmDeleteDialog"
          @submit="getDataAsync"
        ></confirm-delete-dialog>
    </div>
</template>
<script>
import AddOrEditUserDialog from '@/views/UserManagement/AddOrEditUserDialog'
import ConfirmDeleteDialog from '@/components/Dialogs/ConfirmDeleteDialog'

export default {
  name: 'Users',
  components: {
    AddOrEditUserDialog,
    ConfirmDeleteDialog
  },
  data () {
    return {
      users: [],
      headers: [
        { text: this.$t('users.email'), value: 'email' },
        { text: this.$t('users.firstName'), value: 'name' },
        { text: this.$t('users.surName'), value: 'surname' },
        { text: this.$t('users.phone'), value: 'phone' },
        { text: this.$t('common.actions'), value: 'actions', sortable: false, width: '15%' }
      ]
    }
  },
  async beforeMount () {
    await this.getDataAsync()
  },
  methods: {
    async getDataAsync () {
      const result = await this.$http.get('/User/GetUsers')
      this.users = result.data
    },
    deleteUser (user) {
      this.$refs.confirmDeleteDialog.dialog = true
      this.$refs.confirmDeleteDialog.id = user.id
    },
    addOrEditUser (user) {
      this.$refs.addOrEditDialog.dialog = true

      if (user) {
        this.$refs.addOrEditDialog.user = JSON.parse(JSON.stringify(user))
      } else {
        this.$refs.addOrEditDialog.init()
      }
    }
  }
}
</script>
