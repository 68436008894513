<template>
    <v-row justify="end" class="mr-10">
      <v-dialog
        v-model="dialog"
        persistent
        max-width="600px"
      >
        <loader v-if="isLoading"></loader>

        <v-card v-else>
          <v-card-title>
            <span class="text-h5"> {{ user.id === '' ? 'Dodaj ':'Edytuj'}} administratora</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form v-model="valid" ref="adminForm" lazy-validation>
                <v-row>
                  <v-col
                    cols="12"
                  >
                    <v-text-field
                      :rules="[...rules.required]"
                      label="Imię"
                      v-model='user.name'
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                  >
                    <v-text-field
                      :rules="[...rules.required]"
                      label="Nazwisko"
                      v-model='user.surname'
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                  >
                    <v-text-field
                      :rules="[...rules.required, ...rules.phone]"
                      label="Telefon"
                      v-model='user.phone'
                    ></v-text-field>
                  </v-col>
                  <v-col
                  v-if="this.user.id === ''"
                    cols="12"
                  >
                    <v-text-field
                      :rules="[...rules.required, ...rules.email]"
                      label="Email"
                      type="search"
                      v-model='user.email'
                      autocomplete="none"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="dialog = false"
            >
              Anuluj
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="submit"
            >
              Zatwierdź
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </template>
<script>
import {
  displayErrors
} from '@/utils/helpers'
import Loader from '@/components/Loader.vue'
export default {
  name: 'add-or-edit-user-dialog',
  data: () => ({
    isLoading: false,
    dialog: false,
    user: null,
    rules: {
      required: [v => !!v || 'Pole wymagane'],
      phone: [v => !v || !!(/^[0-9+\s-]{8,13}$/i.exec(v)) || 'Błędny numer telefonu.'],
      email: [v => !v || !!(/^\S+@\S+\.\S+$/i.exec(v)) || 'Błędny adres email.']
    },
    valid: true
  }),
  components: {
    Loader
  },
  beforeMount () {
    this.init()
  },
  methods: {
    init () {
      this.user = {
        id: '',
        name: '',
        surname: '',
        email: '',
        phone: '',
        role: 1
      }
    },
    async submit () {
      if (!this.$refs.adminForm.validate()) {
        displayErrors('Uzupełnij wymagane pola lub popraw błędne wartości.')
        return
      }
      this.isLoading = true
      const isAdd = this.user.id === ''

      try {
        const result = await this.$http.post(isAdd
          ? '/User/CreateUser'
          : '/User/UpdateUser', this.user)

        if (result.status === 200) {
          this.dialog = false
          this.$emit('submit')
        }
      } catch (e) {
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>
