<template>
    <v-card class="loader-card">
        <div class="loader-container">
            <v-progress-circular
              :size="160"
              :width="8"
              color="#436074"
              indeterminate
            ></v-progress-circular>
        </div>
      </v-card>
</template>

<style scoped>
    .loader-card{
        min-height:30vh;
        min-width: 10vh;
    }

    .loader-container{
        margin-left: calc(50% - 7vh);
        padding-bottom: 7vh;
        padding-top: 7vh;
        display: block;
    }
  </style>
<script>
export default {
  name: 'loader'
}
</script>
